import React from "react";
import Header from "../components/sidebar/Sidebar";
import Layout from "../components/layout/Layout";
import AboutSection from "../components/content/about/AboutSection";
import EducationSection from "../components/content/education/EducationSection";
import ExperienceSection from "../components/content/experience/ExperienceSection";
import ProjectsSection from "../components/content/projects/ProjectsSection";
import CertificationsSection from "../components/content/certification/CertificationsSection";

const IndexPage = () => {
  return (
    <Layout>
      <div className="grid grid-cols-1 xl:grid-cols-3 gap-x-16">
        <div className="table xl:fixed">
          <Header />
        </div>
        <div className="hidden xl:table" />
        <div className="xl:col-span-2">
          <div className="grid grid-cols-1 gap-y-12">
            {/* <AboutSection /> */}
            <ExperienceSection />
            <EducationSection />
            <CertificationsSection />
            <ProjectsSection />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
