import React, { ReactNode, useMemo } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Scrollspy from "react-scrollspy";
import TextTransition from "react-text-transition";

const NavItem = ({
  children,
  to,
  className,
}: {
  children: ReactNode;
  to: string;
  className?: string;
}) => {
  const isActive = useMemo(() => className === "active", [className]);

  return (
    <li
      className={`mb-3 text-xl font-mono uppercase ${
        isActive ? "font-normal" : "font-light"
      }`}
    >
      <AnchorLink
        offset="50"
        href={`#${to}`}
        className="no-style underline-hover"
      >
        <TextTransition text={isActive ? "▶" : ""} inline noOverflow />
        <span
          className={isActive ? "mr-2" : "mr-0"}
          style={{ transition: "margin 0.25s" }}
        />
        {children}
      </AnchorLink>
    </li>
  );
};

const NavSection = () => {
  return (
    <Scrollspy
      items={[
        // "aboutSection",
        "experienceSection",
        "educationSection",
        "certificationsSection",
        "projectsSection",
      ]}
      currentClassName="active"
      offset={-50}
    >
      {/* <NavItem to="aboutSection">About</NavItem> */}
      <NavItem to="experienceSection">Experience</NavItem>
      <NavItem to="educationSection">Education</NavItem>
      <NavItem to="certificationsSection">Certifications</NavItem>
      <NavItem to="projectsSection">Projects</NavItem>
    </Scrollspy>
  );
};

export default NavSection;
