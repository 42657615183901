import React from "react";
import Badge from "../common/Badge";
import ContentCard from "../common/ContentCard";
import { Experience } from "./ExperienceSection";

const ExperienceDetailItem = ({
  title,
  description,
}: {
  title?: string;
  description: string;
}) => {
  return (
    <li className="pb-2">
      {title && (
        <>
          <Badge>
            <b className="font-semibold">{title}</b>
          </Badge>{" "}
        </>
      )}
      {description}
    </li>
  );
};

interface Props {
  experience: Experience;
}

const ExperienceCard = ({ experience }: Props) => {
  return (
    <ContentCard
      key={experience.company}
      title={experience.position}
      subtitle={experience.type}
      description={experience.company}
      ribbon={
        <>
          {experience.fromYear} -{" "}
          <span
            className={experience.toYear === "Present" ? "font-normal" : ""}
          >
            {experience.toYear}
          </span>
        </>
      }
    >
      {experience.contents.length ? (
        <ul className="pl-4 list-disc font-light">
          {experience.contents.map((content) => (
            <ExperienceDetailItem
              key={content.title}
              title={content.title}
              description={content.description}
            />
          ))}
        </ul>
      ) : (
        <i>Working in Progress!</i>
      )}
      {experience.technologies && (
        <>
          <div className="inline-block flex gap-2 mt-2">
            Technology Used:
            {experience.technologies.map((technology) => (
              <Badge>{technology}</Badge>
            ))}
          </div>
        </>
      )}
    </ContentCard>
  );
};

export default ExperienceCard;
