import clsx from "clsx";
import React, { ReactNode, useState } from "react";
import Badge from "./Badge";

interface Props {
  title: string;
  subtitle?: string;
  description?: string;
  ribbon?: string | ReactNode;
  children?: ReactNode;
}

const ContentCard = ({
  title,
  subtitle,
  description,
  ribbon,
  children,
}: Props) => {
  const [expand, setExpand] = useState(false);

  return (
    <>
      <div
        className={clsx(
          "select-none bg-gray-100 dark:bg-gray-800 p-5 flex justify-between flex-col-reverse sm:flex-row gap-3",
          {
            "transition hover:bg-gray-200 dark:hover:bg-gray-700 active:bg-gray-100 dark:active:bg-gray-800 cursor-pointer":
              children,
          }
        )}
        onClick={() => {
          setExpand(!expand);
        }}
      >
        <div className="flex flex-col justify-around">
          <div className="text-lg font-semibold">
            {title}
            {subtitle && (
              <>
                <span className="font-light text-gray-400 dark:text-gray-500 mx-1.5">
                  |
                </span>
                <small className="font-light text-gray-500 dark:text-gray-400 text-sm">
                  {subtitle}
                </small>
              </>
            )}
          </div>
          {description && <div className="font-light">{description}</div>}
        </div>

        <div className="sm:mb-0 flex flex-row justify-between items-center gap-2">
          <Badge>{ribbon}</Badge>
          {children && (
            <i
              className={clsx("mr-1", {
                "icon-arrow-down": !expand,
                "icon-arrow-up": expand,
              })}
            />
          )}
        </div>
      </div>

      {children && (
        <div
          className={clsx("px-5", {
            hidden: !expand,
          })}
        >
          {children}
        </div>
      )}
    </>
  );
};

export default ContentCard;
