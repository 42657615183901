import React from "react";
import SectionTitle from "../common/SectionTitle";
import CertificationCard from "./CertificationCard";

const CertificationsSection = () => {
  return (
    <div id="certificationsSection">
      <SectionTitle>Certifications</SectionTitle>

      <div className="grid grid-cols-1 gap-y-4">
        <CertificationCard
          name="Microsoft Certified: Azure Developer Associate"
          issued="02/2022"
          expiration="02/2025"
          description="Azure Developer Associate earners certification validates the skills and knowledge to design, build, test, and maintain cloud applications and services on Microsoft Azure. Candidates participate in all phases of cloud development from requirements definition and design, to development, deployment, and maintenance."
          credential="https://learn.microsoft.com/en-gb/users/phwt/credentials/9EE9FDA12307DA2C"
        />
        <CertificationCard
          name="Microsoft Certified: Azure Data Fundamentals"
          issued="02/2022"
          description="Azure Data Fundamentals certification earners have demonstrated foundational knowledge of core data concepts and how they are implemented using Microsoft Azure data services"
          credential="https://learn.microsoft.com/en-gb/users/phwt/credentials/EF09BC1E0BD89011"
        />
        <CertificationCard
          name="Microsoft Certified: Azure Fundamentals"
          issued="09/2021"
          description="Azure Fundamentals certification earners has demonstrated foundational level knowledge of cloud services and how those services are provided with Microsoft Azure"
          credential="https://learn.microsoft.com/en-gb/users/phwt/credentials/E68E808965173435"
        />
        <CertificationCard
          name="AWS Academy Graduate - AWS Academy Cloud Foundations"
          issued="04/2021"
          description="Overall understanding of cloud computing concepts, independent of specific technical roles. Overview of cloud concepts, AWS core services, security, architecture, pricing, and support"
          credential="https://www.credly.com/badges/f0ccb1b3-a928-4e13-aa73-baf54c976250"
        />
      </div>
    </div>
  );
};

export default CertificationsSection;
