import React from "react";

interface Props {
  showMore: boolean;
  setShowMore: (value: boolean) => void;
  label: {
    hidden: string;
    visible: string;
  };
}

const CollapseToggle = ({ showMore, setShowMore, label }: Props) => {
  return (
    <>
      <span
        className="text-sm font-extralight block mt-1.5 cursor-pointer hover:opacity-50 transition"
        onClick={() => {
          setShowMore(!showMore);
        }}
      >
        {!showMore && (
          <>
            <i className="icon-arrow-down mr-2" />
            {label.hidden}
          </>
        )}
        {showMore && (
          <>
            <i className="icon-arrow-up mr-2" />
            {label.visible}
          </>
        )}
      </span>
    </>
  );
};

export default CollapseToggle;
