import React from "react";
import SectionTitle from "../common/SectionTitle";
import ContentCard from "../common/ContentCard";

const EducationSection = () => {
  return (
    <div id="educationSection">
      <SectionTitle>Education</SectionTitle>

      <div className="grid grid-cols-1 gap-y-4">
        <ContentCard
          title="B.Sc. Information Technology"
          subtitle="Software Engineering Track"
          description="King Mongkut’s Institute of Technology Ladkrabang"
          ribbon={<>2018 - 2022</>}
        >
          <ul className="pl-4 list-disc font-medium leading-relaxed">
            <li>Relevant Coursework</li>
            <ul className="pl-4 list-disc font-light mb-2">
              <li>Problem Solving in Information Technology</li>
              <li>Object-Oriented Programming</li>
              <li>Data Structures and Algorithms</li>
              <li>Database System Concepts</li>
              <li>Web Programming</li>
              <li>Human Interface Design</li>
              <li>Discrete Mathematics</li>
              <li>Probability and Statistics</li>
              <li>Information Technology Project Management</li>
              <li>Software Verification and Validation</li>
            </ul>
            <li>Extra-Curricular Activities</li>
            <ul className="pl-4 list-disc font-light">
              <li>
                Teaching Director and Teaching Assistance - ITCAMP 15 (Internet
                of Things)
              </li>
              <li>Teaching Assistance - IT KMITL Pre-Programming 2019</li>
              <li>Teaching Assistance - Object-Oriented Programming 2020</li>
            </ul>
          </ul>
        </ContentCard>
      </div>
    </div>
  );
};

export default EducationSection;
