import React from "react";
import { Helmet } from "react-helmet";
import { ThemeProvider, useTheme } from "../../module/ThemeContext";

interface Props {
  children: React.ReactNode;
}

const Layout = ({ children }: Props) => {
  return (
    <ThemeProvider>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <meta name="description" content="Phuwathid Summaviwat's Portfolio" />
        <meta property="og:title" content="phwt.me" />
        <meta property="og:image" content="/cover.jpg" />
        <meta property="og:description" content="Phuwathid Summaviwat's Portfolio" />
        <meta property="og:url" content="phwt.me" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <title>phwt.smwt</title>
      </Helmet>
      <div className="container min-w-full p-6 sm:p-12 lg:p-16 2xl:px-64 bg-white dark:bg-gray-900 text-black dark:text-gray-50">
        {children}
      </div>
    </ThemeProvider>
  );
};

export default Layout;
