import clsx from "clsx";
import React, { useCallback } from "react";
import { useTheme } from "../../module/ThemeContext";
import NavSection from "./NavSection";
import SocialSection from "./SocialSection";
import TitleSection from "./TitleSection";

export const ThemeSelector = () => {
  const { theme, setTheme } = useTheme();

  const handleThemeToggle = useCallback(
    (e) => {
      e.preventDefault();
      setTheme(theme === "dark" ? "light" : "dark");
    },
    [theme]
  );

  return (
    <a href="#" onClick={handleThemeToggle} aria-label="Toggle Dark/Light Mode">
      <i
        className={clsx("ml-1.5 leading-4 fas", {
          "fa-moon text-xl": theme === "dark",
          "fa-sun text-2xl": theme !== "dark",
        })}
      />
    </a>
  );
};

const Sidebar = () => {
  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-1 gap-y-2.5">
        <TitleSection />

        <div className="block sm:flex sm:justify-end xl:block">
          <SocialSection />
        </div>
      </div>

      <hr className="my-6" />

      <span className="hidden xl:block">
        <NavSection />
      </span>

      <hr className="my-6 hidden xl:block" />

      <span className="hidden xl:block">
        <ThemeSelector />
      </span>
    </>
  );
};

export default Sidebar;
