import React, { useMemo, useState } from "react";
import SectionTitle from "../common/SectionTitle";
import ExperienceCard from "./ExperienceCard";
import CollapseToggle from "../common/CollapseToggle";
import { TransitionGroup, CSSTransition } from "react-transition-group";

export interface Experience {
  order: number;
  fromYear: string;
  toYear: string;
  type: string;
  position: string;
  company: string;
  contents: {
    title: string;
    description: string;
  }[];
  technologies: string[];
  hidden?: boolean;
}

const experiences: Experience[] = [
  {
    order: 0,
    fromYear: "07/2019",
    toYear: "12/2019",
    type: "Internship",
    position: "Software Developer",
    company: "Epsilon Solution and Consultant Co., Ltd.",
    contents: [
      {
        title: "",
        description:
          "Enhances the user interface of internal systems to make them more modern and user-friendly.",
      },
      {
        title: "",
        description:
          "Develops a data migration tool to extract the data from the legacy system's report to the database of the new system.",
      },
    ],
    technologies: ["JavaScript", "C#"],
    hidden: true,
  },
  {
    order: 1,
    fromYear: "04/2020",
    toYear: "07/2021",
    type: "Internship / Contract",
    position: "Full-stack Developer",
    company: "Aileen Solutions Co., Ltd.",
    contents: [
      {
        title: "",
        description:
          "Modernize a legacy system with a modern frontend framework featuring all new user interfaces tailored for providing the best possible user experience.",
      },
      {
        title: "",
        description:
          "Web-based diagram tool providing a visual summary of the risk assessment, designed to be accessible by anyone, from any device and from anywhere",
      },
    ],
    technologies: ["ASP.NET", "C#", "React", "Next.js", "TypeScript", "Docker"],
    hidden: true,
  },
  {
    order: 2,
    fromYear: "08/2021",
    toYear: "04/2022",
    type: "Internship",
    position: "Software Developer",
    company: "ExxonMobil Limited",
    contents: [
      {
        title: "",
        description:
          "Delivers solutions that promote integration across entities both within and outside ExxonMobil, accelerating the organization’s digital capabilities.",
      },
    ],
    technologies: ["Azure", ".NET Core", "C#", "Angular", "Terraform"],
  },
  {
    order: 3,
    fromYear: "06/2022",
    toYear: "Present",
    type: "Full-time",
    position: "Full-stack Developer",
    company: "ExxonMobil Limited",
    contents: [
      {
        title: "",
        description:
          "Effectively deliver products and solutions to customers through system modernization and improved connectivity.",
      },
    ],
    technologies: ["Azure", ".NET Core", "C#", "Angular", "Terraform"],
  },
];

const ExperienceSection = () => {
  const [showHidden, setShowHidden] = useState(false);

  const sortedExperiences = useMemo(() => {
    return experiences.sort((i, j) => j.order - i.order);
  }, []);

  const filteredExperiences = useMemo(
    () => sortedExperiences.filter((e) => showHidden || !e.hidden),
    [showHidden, sortedExperiences]
  );

  return (
    <div id="experienceSection">
      <SectionTitle>Experience</SectionTitle>

      <div className="grid grid-cols-1 gap-y-4">
        <TransitionGroup component={null}>
          {filteredExperiences.map((experience) => (
            <CSSTransition
              key={experience.order}
              timeout={300}
              classNames="item"
            >
              <ExperienceCard experience={experience} />
            </CSSTransition>
          ))}
        </TransitionGroup>

        <CollapseToggle
          showMore={showHidden}
          setShowMore={setShowHidden}
          label={{
            hidden: "Show past contract work and internships",
            visible: "Showing past contract work and internships",
          }}
        />
      </div>
    </div>
  );
};

export default ExperienceSection;
