import Badge from "../common/Badge";

export interface Project {
  year?: string;
  name: string;
  excerpt: string;
  tags: string[];
  links: { [key: string]: string };
}

const ProjectLink = ({
  icon,
  href,
  ariaLabel,
}: {
  icon: string;
  href: string;
  ariaLabel: string;
}) => (
  <a
    href={href}
    className="text-lg"
    target="_blank"
    rel="noreferrer"
    aria-label={ariaLabel}
  >
    <i className={icon} />
  </a>
);

const ProjectCard = ({ year, name, excerpt, tags, links }: Project) => {
  return (
    <div className="bg-gray-100 dark:bg-gray-800 p-4 flex flex-col justify-between gap-4">
      <div>
        <div className="text-lg font-semibold">
          {name} {year && <span className="font-extralight">({year})</span>}
        </div>
        <div className="font-light">{excerpt}</div>
      </div>

      <div className="flex justify-between items-end">
        <div className="flex gap-2 flex-wrap">
          {tags.map((tag) => (
            <Badge key={tag}>{tag}</Badge>
          ))}
        </div>

        <div className="flex gap-2 items-end">
          {links.website && (
            <ProjectLink
              icon="fas fa-globe"
              href={links.website}
              ariaLabel="View this project's website"
            />
          )}
          {links.github && (
            <ProjectLink
              icon="fab fa-github"
              href={links.github}
              ariaLabel="View this project on GitHub"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
