import SectionTitle from "../common/SectionTitle";
import ProjectCard, { Project } from "./ProjectCard";

const projects: Project[] = [
  {
    year: "2018",
    name: "StackBehavior",
    excerpt:
      "Stack Overflow data analysis in technology trends, user behavior and activities",
    tags: ["Python", "Google BigQuery"],
    links: {
      github: "https://github.com/phwt/StackBehavior",
      website: "https://phwt.github.io/StackBehavior/",
    },
  },
  {
    year: "2018",
    name: "Greenigate",
    excerpt:
      "Automatic Plant Watering IoT with mobile app integration for configuration and scheduling",
    tags: ["Arduino", "NodeMCU"],
    links: { github: "https://github.com/phwt/Greenigate" },
  },
  {
    year: "2019",
    name: "Code-Notes",
    excerpt: "Desktop Text Editor inspired by Visual Studio Code",
    tags: ["Java", "JavaFX"],
    links: { github: "https://github.com/phwt/Code-Notes" },
  },
  {
    year: "2019",
    name: "DMS",
    excerpt: "Document Management System",
    tags: ["Django", "JavaScript"],
    links: { github: "https://github.com/phwt/DMS" },
  },
  {
    year: "2020",
    name: "Parentchana",
    excerpt: "School-Parent Relations Mobile Application",
    tags: ["React Native", "Firebase"],
    links: { github: "https://github.com/phwt/Parentchana" },
  },
  {
    year: "2020",
    name: "DMS",
    excerpt:
      "Document Management System rewritten in React and deployed on AWS",
    tags: ["AWS", "Terraform", "React", "Next.js", "TypeScript"],
    links: { github: "https://github.com/phwt/DMS-AWS" },
  },
  {
    name: "phwt.me",
    excerpt: "The website you're currently on",
    tags: ["Gatsby", "React", "TypeScript", "Tailwind CSS"],
    links: { website: "https://phwt.me" },
  },
  {
    name: "Devesitory",
    excerpt: "Knowledge sharing; developer-style",
    tags: ["Knowledge, Research and Learning"],
    links: { website: "https://fb.com/Devesitory" },
  },
];

const ProjectsSection = () => {
  return (
    <div id="projectsSection">
      <SectionTitle>Projects</SectionTitle>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {projects.reverse().map((project) => (
          <ProjectCard
            key={project.name + project.year}
            year={project.year}
            name={project.name}
            excerpt={project.excerpt}
            tags={project.tags}
            links={project.links}
          />
        ))}
      </div>
    </div>
  );
};

export default ProjectsSection;
