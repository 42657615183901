import ContentCard from "../common/ContentCard";

interface Props {
  name: string;
  issued: string;
  expiration?: string;
  description: string;
  credential: string;
}

const CertificationCard = ({
  name,
  issued,
  expiration,
  description,
  credential,
}: Props) => {
  return (
    <ContentCard
      title={name}
      subtitle={expiration ? `Expiration: ${expiration}` : "No Expiration Date"}
      ribbon={`Issued: ${issued}`}
    >
      {description}
      <a className="block mt-3" target="_blank" href={credential}>
        See credential
      </a>
    </ContentCard>
  );
};

export default CertificationCard;
